import api from '@/services/api';
import { get } from 'lodash';
import router from '@/router';
import store from '@/store';
import i18n from '@/locales/i18n';

const ERROR_CODE_VALIDATE = 422;
const ERROR_CODE_NOT_FOUND = 404;
const ERROR_CODE_UNAUTHORIZED = 401;
const ERROR_CODE_BAD_REQUEST = 400;
const ERROR_CODE_FORBIDDEN = 403;
const ERROR_CODE_SERVER = 500;
const ERROR_TOO_MANY_REQUESTS = 429;

export default {
  async get (url, params = {}) {
    try {
      const result = await api.get(url, params);
      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      return this.handleError(error);
    }
  },
  async getWithLoading (url, params = {}) {
    try {
      store.dispatch('setIsShowLoading', true);
      const result = await api.get(url, params);
      store.dispatch('setIsShowLoading', false);

      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      store.dispatch('setIsShowLoading', false);
      return this.handleError(error);
    }
  },
  async post (url, params = {}) {
    try {
      store.dispatch('setIsShowLoading', true);
      const result = await api.post(url, params);
      store.dispatch('setIsShowLoading', false);

      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      store.dispatch('setIsShowLoading', false);
      return this.handleError(error);
    }
  },
  async postWithoutLoading (url, params = {}) {
    try {
      const result = await api.post(url, params);
      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      return this.handleError(error);
    }
  },
  async put (url, params = {}) {
    try {
      store.dispatch('setIsShowLoading', true);
      const result = await api.put(url, params);
      store.dispatch('setIsShowLoading', false);

      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      store.dispatch('setIsShowLoading', false);
      return this.handleError(error);
    }
  },
  async patch (url, params = {}) {
    try {
      const result = await api.patch(url, params);
      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      return this.handleError(error);
    }
  },
  async delete (url, params = {}) {
    try {
      store.dispatch('setIsShowLoading', true);
      const result = await api.delete(url, params);
      store.dispatch('setIsShowLoading', false);
      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      store.dispatch('setIsShowLoading', false);
      console.log('catch', error);
      return this.handleError(error);
    }
  },
  async download (url, params = {}) {
    try {
      const result = await api.get(url, params, { responseType: 'blob' });
      const { status, data, headers } = result;

      let filename = '';
      const disposition = headers['content-disposition'];
      if (disposition.indexOf('filename*=') !== -1) {
        filename = decodeURIComponent(disposition.split('filename*=')[1].replace('utf-8\'\'', ''));
      } else {
        filename = disposition.split(';')[1].split('filename=')[1].split('"')[1];
      }

      return {
        status: true,
        code: status,
        data,
        headers,
        filename,
      };
    } catch (error) {
      return this.handleError(error);
    }
  },
  async downloadPost (url, params = {}) {
    try {
      store.dispatch('setIsShowLoading', true);
      const result = await api.post(url, params, { responseType: 'blob' });
      store.dispatch('setIsShowLoading', false);

      const { status, data, headers } = result;
      return {
        status: true,
        code: status,
        data,
        headers,
      };
    } catch (error) {
      store.dispatch('setIsShowLoading', false);
      return this.handleError(error);
    }
  },
  async formUpload (url, params = {}) {
    try {
      store.dispatch('setIsShowLoading', true);
      const result = await api.post(url, params, {
        'Content-Type': 'multipart/form-data',
      });
      store.dispatch('setIsShowLoading', false);
      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      store.dispatch('setIsShowLoading', false);
      return this.handleError(error);
    }
  },
  async formUploadWithoutLoading (url, params = {}) {
    try {
      const result = await api.post(url, params, {
        'Content-Type': 'multipart/form-data',
      });
      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      return this.handleError(error);
    }
  },
  handleError (error) {
    const { response, request } = error;
    const { status, data } = response;
    const errorCode = status || ERROR_CODE_SERVER;

    if (response) {
      this.handleResponseError(response);
    } else if (request) {
      this.handleNoResponse(request);
    } else {
      console.log('Error', error.message);
    }

    return {
      status: false,
      code: errorCode,
      data,
    };
  },
  handleResponseError (response) {
    const { status, data } = response;

    const errorCode = status || ERROR_CODE_SERVER;
    const errorMessage = data ? (data.message || data.error) : i18n.t('common.message.error');

    switch (errorCode) {
      case ERROR_CODE_UNAUTHORIZED: // 401
        if (get(router, 'history.router.history.current.name') !== 'login') {
          store.dispatch('snackbar/addError', errorMessage);
          store.commit('userStore/UNSET_USER');
          router.push({ name: 'login' });
        }
        break;
      case ERROR_CODE_VALIDATE: // 422
      case ERROR_CODE_BAD_REQUEST: // 400
        return {
          status: false,
          errorCode: errorCode,
          data: data ? data.errors : {},
          message: errorMessage,
        };

      case ERROR_CODE_NOT_FOUND: // 404
        store.dispatch('snackbar/addError', i18n.t('common.message.not_found'));
        break;
      case ERROR_CODE_FORBIDDEN: // 403
        store.dispatch('snackbar/addError', i18n.t('common.message.no_permission'));
        break;
      case ERROR_CODE_SERVER: // 500
        store.dispatch('snackbar/addError', i18n.t('common.message.error'));
        break;
      case ERROR_TOO_MANY_REQUESTS: // 429
        store.dispatch('snackbar/addError', i18n.t('common.message.error'));
        break;
      default:
        console.log('has error');
        break;
    }
  },
  handleNoResponse (request) {
    console.log('handleNoResponse', request);
  },

  ERROR_CODE_VALIDATE,
  ERROR_CODE_NOT_FOUND,
  ERROR_CODE_UNAUTHORIZED,
  ERROR_CODE_BAD_REQUEST,
  ERROR_CODE_FORBIDDEN,
  ERROR_CODE_SERVER,
};
