export default {
  label: {
    search: '検索',
    require: '必須',
    create: '新規作成',
    logout: 'ログアウト',
    yen: '円',
    point: 'ポイント',
    limit_perpage: '表示上限数をオーバーしたため、最初の1,000件のみ表示させています。',
    action: 'アクション',
    confirm_title: '確認',
    from: 'から',
    to: 'まで',
    system_select: 'システム選択',
    client_select: 'クライアント選択',
    partner_company_select: '提携企業選択',
    partner_store_select: '提携店選択',
    status_select: 'ステータス選択',
    use_type_select: 'ユーザー区分選択',
    type_select: 'タイプ選択',
    receive_mail: 'メールを受信',
    today: '今日',
    subject: '件',
    time: '倍',
    file: 'ファイル',
  },
  button: {
    search: '検索',
    import_csv: 'CSV投入',
    export_csv: 'CSV出力',
    create: '新規作成',
    register: '登録',
    edit: '訂正',
    keep: '保存',
    detail: '詳細',
    delete: '削除',
    complete: '完了',
    cancel: 'キャンセル',
    yes: 'はい',
    no: 'いいえ',
    back: '戻る',
    back_list: '一覧へ戻る',
    modal_back_list: '一覧へ戻る',
    back_to_calendar: 'カレンダーへ戻る',
    download: 'ダウンロード',
    select_file: 'ファイルを選択',
    upload: '反映',
    confirm: '確認',
    ok: 'OK',
    export_file: 'ファイル出力',
    error_data: 'エラーデータ',
    next: '次へ',
    close: '閉じる',
    re_calc: '再計算',
  },
  message: {
    success: '正常に保存しました。',
    error: 'エラーが発生しました。',
    no_data: 'データなし。',
    loading: 'ローディング。',
    completed: '完了しました。',
    no_permission: 'この操作を実行するのに十分な権限がありません。',
    not_found: '該当データが存在しないです。',
  },
  options: {
    all: 'すべて',
    active: 'Active',
    inactive: 'Inactive',
  },
  table: {
    nodata: 'データなし。',
  },
  dialog_delete: {
    title: '項目の削除',
    message: 'こちらのシステムを削除してもよろしいですか。',
  },
  dialog_delete_success: {
    title: '項目の削除',
    message: '削除が完了しました。',
  },
  dialog_delete_error: {
    title: '項目の削除',
    message: 'これは紐づけているデータがありますので、削除できません。',
  },
  dialog_cancel_success: {
    message: 'キャンセルが完了しました。',
  },
  dialog_logout: {
    title: '項目の削除',
    message: 'システムからのログアウトを確認',
  },
  dialog_import: {
    zip_title: 'インポートファイルを選択 (ZIP)',
    label: {
      record_total: 'レコード数 : ',
      success: '成功 : ',
      fail: '失敗 : ',
      line: 'ライン{line} : ',
    },
    message: {
      import_complete: 'インポートが完了しました。',
    },
  },
  placeholder: {
    date_select: '日付選択',
  },
  sidebar: {
    home: 'ホーム',
    common: 'Common',
    list_system: 'システム一覧',
    list_client: 'クライアント一覧',
    list_company: '提携企業一覧',
    list_store: '提携店一覧',
    list_user: 'ユーザ一覧',
    list_transaction: '取引管理',
    list_promotion: 'プロモーション一覧',
    calendar: 'カレンダー',
    report: 'レポート',
    daily_performance: '日次実績レポート',
    monthly_performance: '月次実績レポート',
    daily_point_ticket: '日次ポイント券利用',
    monthly_point_ticket: '月次ポイント券利用',
    log_sent_batch: ' 送信バッチログ',
    log_received_batch: '受信バッチログ',
    log_user_operation: '操作ログ',
    list_invoice: '請求書発行機能',
    maintain_setting: '設定 (メンテナンス)',
    tax_setting: '税率設定',
    manual_import: '手動データインポート',
    list_information: 'インフォメーション一覧',
  },
  breadcrumb: {
    confirm: '確認',
    complete: '完了',
  },
  api_option: {
    available: '有',
    no_available: 'ない',
  },
  status_option: {
    active: 'Active',
    inactive: 'Inactive',
  },
  hint: {
    select_system: 'システム選択',
    select_client: 'クライアント選択',
    select_company: '提携企業選択',
    select_store: '提携店選択',
  },
  pagination: '件{from}-{to}/{total}件',
  status_company: {
    active: 'オープン',
    inactive: 'クローズ',
  },
};
