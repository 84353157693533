export default {
  breadcrumbs: {
    list: 'インフォメーション一覧',
    register: 'インフォメーション登録',
    edit: 'インフォメーション変更',
    detail: 'インフォメーションの閲覧',
    approve: 'インフォメーションの承認',
  },
  label: {
    information: 'インフォメーション',
    message: 'メッセージ',
    scope: '閲覧先',
    title: 'タイトル',
    display_period: '表示期間',
    important: '重要度',
    attachment: '添付ファイル',
    start_date: '開始日',
    end_date: '終了日',
    information_important: '重要度選択',
    information_status: 'ステータス選択',
    posting_period: '掲示期間',
    registed_user: '登録者',
    status: 'ステータス',
    confirm_reject: '差し戻し確認',
    reject_reason: '差し戻し理由',
    one_hundred_year_later: '100年後',
  },
  scopes: {
    all: 'すべて',
    system: '指定システム',
    client: '指定クライアント',
    company: '指定提携企業',
  },
  button: {
    all: 'すべて',
    system_select: 'システムを選択',
    client_select: 'クライアントを選択',
    company_select: '提携企業を選択',
    store_select: '提携店を選択',
    register: 'インフォメーション登録',
    select_file: 'ファイルを選択',
    request_approve: '承認依頼',
    approve: '承認',
    reject: '差し戻し',
    list: 'すべてのインフォメーションはこちら',
  },
  message: {
    selected_system_quantity: '選ばれた{quantity}のシステム',
    selected_client_quantity: '選ばれた{quantity}のクライアント',
    selected_company_quantity: '選ばれた{quantity}の提携企業',
    selected_store_quantity: '選ばれた{quantity}の提携店',
    scope_required: '閲覧先を入力してください。',
    expired_information: '終了',
    confirm_update: '訂正する場合、また承認依頼が必要となります。',
    virus_scanning: 'ウイルススキャン中',
    virus_not_detect: 'ウイルスが検出しない',
    virus_detected: 'ウイルスが検出した',
    attachment_not_download: '※添付ファイルはダウンロードできません。',
    approve_required: '承認作業が必要となります。承認者へ依頼してください。',
  },
  important: {
    low: '低',
    normal: '標準',
    high: '高',
    urgent: '緊急',
  },
  status: {
    waiting_approve: '承認待ち',
    approved: '承認済',
    rejected: '差し戻し',
  },
  table: {
    important: '重要度',
    title: 'タイトル',
    start_date: '掲示開始日',
    end_date: '掲示終了日',
    registed_user: '登録者',
    attachment_mark: '添付',
    status: 'ステータス',
    posting_period: '掲示期間',
  },
  dialog: {
    delete: {
      message_confirm: 'こちらのインフォメーションを削除してもよろしいですか。',
      message_success: '削除が完了しました。',
    },
  },
};
